import Software1 from 'assets/images/Offer/software1.png';
import Software2 from 'assets/images/Offer/software2.png';
import Software3 from 'assets/images/Offer/software3.png';
import Software4 from 'assets/images/Offer/software4.png';
import Software5 from 'assets/images/Offer/software5.png';
import SoftwarePaymentIntegration from 'assets/images/Offer/softwarePaymentIntegration.png';
import Deep1 from 'assets/images/Offer/deep1.png';
import Deep2 from 'assets/images/Offer/deep2.png';
import Deep3 from 'assets/images/Offer/deep3.png';
import Deep4 from 'assets/images/Offer/deep4.png';
import Deep5 from 'assets/images/Offer/deep5.png';
import Hardware1 from 'assets/images/Offer/hard1.png';
import Hardware2 from 'assets/images/Offer/hard2.png';
import Hardware3 from 'assets/images/Offer/hard3.png';
import Hardware4 from 'assets/images/Offer/hard4.png';

export const softwareData = [
    {
        imgSrc: Software1,
        title: 'Web Apps',
        desc: `Benefit from full-cycle web development services – from idea
                    to a full-featured app with 24/7 support and maintenance. We
                    work on turnkey projects of varying complexity, using the
                    latest technologies and frameworks.`,
        descSecondary: `Our engineers can implement different kinds of application
                    architecture, including microservices, serverless, headless,
                    cloud-native, or progressive web apps.`,
        href: '/contact',
    },
    {
        imgSrc: Software2,
        title: 'Mobile Apps',
        desc: `Create a native iOS or Android application that requires complex logic with Codahead. We can build MVP for you as well as do technical assessment of the existing solution.`,
        descSecondary: `We're experienced with integrations with 3rd parties including payment gateways.`,
        href: '/contact',
    },
    {
        imgSrc: Software3,
        title: 'Admin panels with advanced data analytics',
        desc: `Codahead team can help you build a customer-centric commerce platform to create an exceptional digital customer experience, underline the value of your offering, and boost online earnings giving you insight into what your customers do.`,
        href: '/contact',
    },
    {
        imgSrc: SoftwarePaymentIntegration,
        title: 'Payment integration',
        desc: `At Codahead, we lead seamless payment integration, specializing in customized solutions, rigorous testing, and unwavering support. From PCI compliance to programming diverse hardware, we ensure secure payment acceptance via RFiD, BLE, or NFC.`,
        descSecondary: `Our expertise includes successful gateway integrations with Braintree, Authorize.net, Stripe, ApplePay, and others, alongside top loyalty card providers like GiveX.`,
        href: '/contact',
    },
    {
        imgSrc: Software4,
        title: 'UX / UI design',
        desc: `Let your product speak for itself with a modern, clean look and intuitive interface. Our designer can improve the usability of your software and enhance users’ engagement with UX/UI best practices.`,
        descSecondary: `We design products for our clients.`,
        href: '/contact',
    },
    {
        imgSrc: Software5,
        title: 'Outsourcing',
        desc: `Have your own dedicated team of developers, QAs, managers, designers or hardware developers.`,
        descSecondary: `If you know what to do and how to achieve it, but don't have the resources, look no further. 
We fit your culture and adapt to your time zone.`,
        href: '/contact',
    },
];

export const deepTechData = [
    {
        imgSrc: Deep1,
        title: 'Machine learning',
        desc: `Drive your business agility and productivity with Artificial Intelligence and Machine Learning services.`,
        descSecondary: `Our team applies in-depth AI/ML expertise to help you leverage the full value of intelligent automation technologies.`,
        href: '/contact',
    },
    {
        imgSrc: Deep2,
        title: 'Data sets & Neural networks training',
        desc: `Codahead can help you with the data by preparing datasets and content indexing for the specific need and train the neural network for you on our internal infrastructure .`,
        href: '/contact',
    },
    {
        imgSrc: Deep3,
        title: 'Image & sound AI processing',
        desc: `We are experienced in computer vision or natural language processing. Image recognition, facial authentication are just a few benefits computer vision can offer to businesses. 
With biometrics or object detection features, you can enhance customer services with cutting-edge solutions.`,
        descSecondary: `And if you would be interested in deep fakes, we got few in our portfolio.`,
        href: '/contact',
    },
    {
        imgSrc: Deep4,
        title: 'Augmented & Virtual reality solutions',
        desc: `Immersive technologies offer opportunities to craft meaningful engagements, reimagine work, and drive value.`,
        descSecondary: `Leverage the power of immersive experience with AR, VR, MR, and 3D services that we offer.`,
        href: '/contact',
    },
    {
        imgSrc: Deep5,
        title: 'Metaverse development service',
        desc: `Innovate with us in the metaverse.`,
        descSecondary: `
We are here to create your immersive virtual worlds with new opportunities through customization and trending Web3 capabilities in the digital world.`,
        href: '/contact',
    },
];

export const hardwareData = [
    {
        imgSrc: Hardware1,
        title: 'Electronics design',
        desc: `Codahead delivers electronic product designs—including hardware and software device solutions.`,
        descSecondary: `Having experience and technical expertise we provide electronic design services including PCB layouts, providing EMC compliance and design it for the manufacturing after tests and production preparation. 
Our in house design area is equipped for variety of designs.`,
        href: '/contact',
    },
    {
        imgSrc: Hardware2,
        title: 'Hardware prototyping',
        desc: `With IoT devices, hardware and software development should go hand in hand. Codahead will work with you to fully understand your hardware requirements and how they would impact our framework choices.`,
        descSecondary: `Keeping your requirements in mind, we’ll make recommendations and build out a prototype to use for testing. Once we’ve worked out the kinks, we can put the hardware into production. Modestly speaking, whenever we bring an Internet of Things device to life, it is top-notch.`,
        href: '/contact',
    },
    {
        imgSrc: Hardware3,
        title: 'Firmware & embedded software development',
        desc: `Codahead can take care of any IoT project and build embedded firmware, human-machine interfaces.`,
        descSecondary: `Our modern software technologies, tool sets, and best practices—such as comprehensive testing and continuous integration—have been tested over a number of projects that we delivered to our partners. 
Our embedded software engineers will leverage C/C++, Python, Shell, QML, Assembler, Lua and create for you design documentation, specification and build the prototype.`,
        href: '/contact',
    },
    {
        imgSrc: Hardware4,
        title: 'Wireless connectivity',
        desc: `Codahead will provide your product with reliable connectivity as we integrate Wi-Fi, Bluetooth, RFiD, NFC, mobile data and other low-energy wireless solutions so you're able to exchange data in a secure manner as keep the data protection on top of the priorities.`,
        href: '/contact',
    },
];
