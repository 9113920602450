import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
    title: string;
    description?: string;
    lang?: string;
    meta?: Array<{
        name: string;
        content: string;
    }>;
}

const SEO: React.FC<SEOProps> = ({
    title,
    description,
    lang = 'en',
    meta = [],
}) => {
    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={title}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
            ].concat(meta)}
        />
    );
};

export default SEO;
